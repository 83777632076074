import * as React from 'react';
import InternalLink from '../soul/navigation/InternalLink';
import { SpeechFrontmatter } from './AgendaRow';
import { SpeakerFrontmatter } from '../speaker/SpeakersRow';
import Flag from '../soul/layout/flag/Flag';
const { toLaxTitleCase } = require('titlecase');

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeechCardProps {
    relativePath: string,
    speech: SpeechFrontmatter
}

const AgendaCard: React.FC<SpeechCardProps> = ({relativePath, speech}) => {
    const speechPageHref = (speechPath: string) => `/${speechPath.replace('.md', '')}/`;
    const fullName = (frontmatter: SpeakerFrontmatter) => `${frontmatter.firstName} ${frontmatter.lastName}`;
    const titleCase = toLaxTitleCase(speech.title)
    return (


        <div className="soul-space-stack-bottom-s">
            <Flag>
                <div className="soul-navigation-card__image">
                    <p className="soul-space-stack-bottom-s">{speech.formattedStartDate} - {speech.formattedEndDate}
                        <span className="soul-font-size-xs a-label--reduced"> CEST</span></p>
                    <p className="a-label--reduced">{speech.location}</p>
                </div>

                <InternalLink to={speechPageHref(relativePath)} className="soul-navigation-card  soul-navigation-card--comfortable">


                    <div className="soul-navigation-card__content">
                        <div className="soul-space-stack-bottom-s">
                            <span className="a-badge  a-badge--default">{speech.session}</span>
                        </div>
                        <div className="soul-navigation-card__title">

                            <span className="soul-font-size-m h-text-bold  soul-space-stack-top-s">{titleCase}</span>
                        </div>
                        <div className="soul-navigation-card__description">
                            {speech.speakers.map(speaker => {
                                return fullName(speaker.childMarkdownRemark.frontmatter);
                            }).join(' · ')}
                        </div>
                        <div className="soul-navigation-card__additional-content">
                            {speech.speakers[0].childMarkdownRemark.frontmatter.organization}
                        </div>
                    </div>
                    <span className="soul-navigation-card__icon"></span>
                </InternalLink>
            </Flag>
        </div>
    );
};

export default AgendaCard;
