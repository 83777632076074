import * as React from 'react';
import SingleColumnLayout from '../layouts/singleColumnLayout/SingleColumnLayout';
import { graphql } from 'gatsby';
import AgendaRow, { Speech } from '../components/agenda/AgendaRow';
import { GridItemSize } from '../components/soul/layout/grid/GridItemSize';
import SEO from '../components/SEO/SEO';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface AgendaPageProps {
    data: {
        allFile: {
            nodes: Speech[]
        }
    }
}

const AgendaPage: React.FC<AgendaPageProps> = ({data}) => {
    const startDateTime = (node: Speech) => node.childMarkdownRemark.frontmatter.startDateTime;
    const dayOne: Speech[] = [];
    const dayTwo: Speech[] = [];
    data.allFile.nodes.forEach((item) => {
        const key = new Date(item.childMarkdownRemark.frontmatter.startDateTime).getDate();
        if (key === 30) {
            dayOne.push(item);
        } else {
            dayTwo.push(item);
        }
    });
    const sortedSpeechesDayOne = dayOne.sort((a, b) => startDateTime(a) === startDateTime(b)? (a.childMarkdownRemark.frontmatter.location < b.childMarkdownRemark.frontmatter.location ? -1 :1):  startDateTime(a) < startDateTime(b) ? -1 : 1);
    const sortedSpeechesDayTwo = dayTwo.sort((a, b) => startDateTime(a) === startDateTime(b)? (a.childMarkdownRemark.frontmatter.location < b.childMarkdownRemark.frontmatter.location ? -1 :1):  startDateTime(a) < startDateTime(b) ? -1 : 1);
    return (
        <SingleColumnLayout>
            <SEO title="ESTECO UM20 | Discover the agenda"
                 description="Discover ESTECO UM20 agenda. Join and learn how engineering professionals from industry and academia are facing the future of product development."/>
            <div className="centered-column">
                <h1 className="page-title">Agenda</h1>

                <h2 className="soul-space-stack-both-m h-color-primary-bold">Day One: 30 September</h2>
                <AgendaRow speeches={sortedSpeechesDayOne} size={GridItemSize.ONE_WHOLE}/>

                <h2 className="soul-space-stack-both-m h-color-primary-bold">Day Two: 1 October</h2>
                <AgendaRow speeches={sortedSpeechesDayTwo} size={GridItemSize.ONE_WHOLE}/>
            </div>
        </SingleColumnLayout>
    );
};

export const pageQuery = graphql`
    query AllSpeechesAndSpeakers {
        allFile(
            filter: {relativeDirectory: {eq: "speeches"}, extension: {eq: "md"}},
            sort: {fields: [childMarkdownRemark___frontmatter___startDateTime], order: [ASC]}
        ) {
            nodes {
                relativePath
                id
                childMarkdownRemark {
                    frontmatter {
                        title
                        startDateTime
                        endDateTime
                        formattedStartDate: startDateTime(formatString: "HH:mm")
                        formattedEndDate: endDateTime(formatString: "HH:mm")
                        location
                        session
                        speakers {
                            id
                            relativePath
                            childMarkdownRemark {
                                frontmatter {
                                    firstName
                                    lastName
                                    jobTitle
                                    organization
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;


export default AgendaPage;
