import * as React from 'react';
import {GridDensity} from '../soul/layout/grid/GridDensity';
import GridItem from '../soul/layout/grid/GridItem';
import {GridItemSize} from '../soul/layout/grid/GridItemSize';
import AgendaCard from './AgendaCard';
import Grid from '../soul/layout/grid/Grid';
import { Speaker } from '../speaker/SpeakersRow';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface AgendaRowProps {
    speeches: Speech[];
    size: GridItemSize;
}

export interface Speech {
    id: string;
    relativePath: string;
    childMarkdownRemark: {
        frontmatter: SpeechFrontmatter
    };
}

export interface SpeechFrontmatter {
    title: string;
    startDateTime: Date;
    endDateTime: Date;
    formattedStartDate: string
    formattedEndDate: string
    location: string;
    session: string;
    speakers: Speaker[];
}

const AgendaRow: React.FC<AgendaRowProps> = ({speeches, size}) => (
    <div>
        {speeches.map(speech => {
            return <AgendaCard relativePath={speech.relativePath} speech={speech.childMarkdownRemark.frontmatter} key={speech.id}/>;
        })}
    </div>
);

export default AgendaRow;
